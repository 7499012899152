import React from "react";

const LogOutIcon = () => (
  <svg className="logout-icon" x="0px" y="0px" viewBox="0 0 20.75 20.971">
    <g id="layer1" transform="translate(-416.34 -206.36)">
      <path
        id="path877"
        d="M425.925,226.8a9.832,9.832,0,0,1-7.275-4.312,9.548,9.548,0,0,1,.212-10.926,9.4,9.4,0,0,1,2.414-2.321,1.147,1.147,0,0,1,1.676,1.021c0,.436-.141.667-.637,1.039a9.594,9.594,0,0,0-.746.659,7.292,7.292,0,0,0,.183,10.621,7.728,7.728,0,0,0,3.62,1.825,8.96,8.96,0,0,0,2.866,0,7.529,7.529,0,0,0,5.072-3.648,7.264,7.264,0,0,0,.152-6.853,6.21,6.21,0,0,0-.858-1.328,6.809,6.809,0,0,0-1.237-1.213c-.563-.461-.693-.665-.693-1.087a1.158,1.158,0,0,1,1.679-1.023,7.561,7.561,0,0,1,1.332,1.08,9.587,9.587,0,0,1,2.6,4.529,9.593,9.593,0,0,1-5.268,11.03,10.04,10.04,0,0,1-3.47.915,7.73,7.73,0,0,1-1.626-.005Zm.462-10.165a1.184,1.184,0,0,1-.606-.556l-.122-.241v-4.059c0-4,0-4.062.1-4.26a1.223,1.223,0,0,1,.72-.605.825.825,0,0,1,.657,0,1.223,1.223,0,0,1,.72.605c.1.2.1.258.1,4.257,0,3.826-.006,4.067-.088,4.246a1.164,1.164,0,0,1-1.057.681A1.317,1.317,0,0,1,426.387,216.63Z"
        transform="translate(0)"
        stroke="#fff"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default LogOutIcon;
