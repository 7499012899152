import React from "react";

const CustomerIcon = () => (
  <svg className="customer-icon" x="0px" y="0px" viewBox="0 0 19.566 26.619">
    <g id="layer1" transform="translate(5.466 -78.656)">
      <path
        id="path881"
        d="M2.507,105.265l-.719-.078a8.33,8.33,0,0,1-5.726-2.7A7.5,7.5,0,0,1-5.364,99.17,16.369,16.369,0,0,1-5.337,95.3,15.314,15.314,0,0,1-.192,85.821l.2-.162-1.641-2.982c-1.484-2.7-1.642-3.006-1.643-3.231a.746.746,0,0,1,.4-.67l.176-.12H4.313c7.867,0,7.279-.03,7.483.386.222.453.248.39-1.537,3.64L8.625,85.659l.2.162a10.462,10.462,0,0,1,1.335,1.239,13.817,13.817,0,0,1,2.256,3.4A16.693,16.693,0,0,1,14,95.493a16.868,16.868,0,0,1,0,3.676,7.732,7.732,0,0,1-1.268,3.117c-1.179,1.561-3.029,2.5-5.647,2.881a20.817,20.817,0,0,1-2.5.1c-1.04.009-1.975.006-2.078-.006Zm4.209-1.753a7.769,7.769,0,0,0,3.81-1.344c1.379-1.043,2.036-2.812,1.923-5.183-.183-3.861-2.135-7.976-4.679-9.862l-.424-.315H1.291l-.424.315c-2.889,2.142-5.029,7.291-4.65,11.19.321,3.307,2.287,4.921,6.424,5.275A31.379,31.379,0,0,0,6.716,103.512Zm-3.25-2.651v-.494l-.256-.091A3.079,3.079,0,0,1,1.282,97.72v-.2H2.924l.036.214a1.47,1.47,0,0,0,.635.876,1.015,1.015,0,0,0,.723.161,1.015,1.015,0,0,0,.723-.161,1.274,1.274,0,0,0,.43-1.823,1.366,1.366,0,0,0-1.166-.608,3.03,3.03,0,0,1-2.148-.923,2.9,2.9,0,0,1,.026-4.185,2.918,2.918,0,0,1,1.134-.741c.144-.053.147-.061.147-.508v-.454H5.171v.893l.46.221a3.043,3.043,0,0,1,1.725,2.468v.2H5.713l-.036-.214a1.47,1.47,0,0,0-.635-.876,1.015,1.015,0,0,0-.723-.161,1.015,1.015,0,0,0-.723.161,1.274,1.274,0,0,0-.43,1.823,1.358,1.358,0,0,0,1.186.6,3.035,3.035,0,0,1,2.128.922,2.918,2.918,0,0,1,0,4.174,2.618,2.618,0,0,1-.775.573,2.643,2.643,0,0,1-.46.188c-.051,0-.068.131-.068.506v.506H3.466ZM8.3,82.74c.711-1.3,1.292-2.378,1.292-2.4s-2.374-.035-5.275-.035-5.275.016-5.275.035.569,1.1,1.265,2.4L1.574,85.1H7.009L8.3,82.74Z"
        transform="translate(0 0)"
        stroke="#656588"
        strokeWidth="0.1"
      />
    </g>
  </svg>
);

export default CustomerIcon;
