import React from "react";

const UserIcon = () => (
  <svg className="user-icon " x="0px" y="0px" viewBox="0 0 20.6 20.6">
    <path
      id="Profile"
      d="M18.586,14.377a12.854,12.854,0,0,0-3.135-1.884,17.052,17.052,0,0,1-2.408-1.345,8.456,8.456,0,0,0,2.1-5.084A7.99,7.99,0,0,0,14.526,2.4C13.974,1.3,12.741,0,10,0S6.025,1.3,5.474,2.4a7.981,7.981,0,0,0-.613,3.669,8.449,8.449,0,0,0,2.1,5.084,16.968,16.968,0,0,1-2.409,1.345,12.863,12.863,0,0,0-3.135,1.884A6.148,6.148,0,0,0,0,18.308,1.667,1.667,0,0,0,1.666,20H18.333A1.667,1.667,0,0,0,20,18.307,6.15,6.15,0,0,0,18.586,14.377Zm.045,4.249a.417.417,0,0,1-.3.125H1.666a.417.417,0,0,1-.3-.125.422.422,0,0,1-.12-.3A4.991,4.991,0,0,1,2.3,15.261a12.7,12.7,0,0,1,2.811-1.65,13.709,13.709,0,0,0,2.708-1.555l.844-.8-.733-.9A7.234,7.234,0,0,1,6.111,6.065,6.91,6.91,0,0,1,6.59,2.959C7.163,1.825,8.309,1.25,10,1.25s2.837.575,3.41,1.708a6.914,6.914,0,0,1,.479,3.106,7.229,7.229,0,0,1-1.817,4.294l-.733.9.844.8a13.738,13.738,0,0,0,2.708,1.555,12.643,12.643,0,0,1,2.811,1.65,4.871,4.871,0,0,1,1.048,3.066A.417.417,0,0,1,18.63,18.626Z"
      transform="translate(0.3 0.3)"
      strokeWidth="0.2"
      opacity="1"
    />
  </svg>
);

export default UserIcon;
