import React from "react";

const WarningIcon = () => (
  <svg className="warning-icon" x="0px" y="0px" viewBox="0 0 28.003 28.003">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f9db49" />
        <stop offset="1" stopColor="#ff7600" />
      </linearGradient>
    </defs>
    <path
      id="Subtraction_18"
      data-name="Subtraction 18"
      d="M-15438-14732a13.911,13.911,0,0,1-9.9-4.1,13.911,13.911,0,0,1-4.1-9.9,13.918,13.918,0,0,1,4.1-9.9,13.909,13.909,0,0,1,9.9-4.1,13.919,13.919,0,0,1,9.9,4.1,13.919,13.919,0,0,1,4.1,9.9,13.909,13.909,0,0,1-4.1,9.9A13.918,13.918,0,0,1-15438-14732Zm0-25.373a11.287,11.287,0,0,0-8.038,3.331,11.3,11.3,0,0,0-3.336,8.043,11.3,11.3,0,0,0,3.336,8.042,11.291,11.291,0,0,0,8.038,3.336,11.3,11.3,0,0,0,8.043-3.336,11.313,11.313,0,0,0,3.335-8.042,11.305,11.305,0,0,0-3.335-8.043A11.3,11.3,0,0,0-15438-14757.372Zm-6.468,13.352a1.914,1.914,0,0,1-1.393-.562,1.942,1.942,0,0,1-.562-1.415,2.03,2.03,0,0,1,.563-1.451,1.868,1.868,0,0,1,1.393-.573,1.835,1.835,0,0,1,1.373.573,2.008,2.008,0,0,1,.546,1.451,1.933,1.933,0,0,1-.546,1.415A1.882,1.882,0,0,1-15444.47-14744.021Zm15.721-.128h0l-11.694-.625v-2.474l11.7-.6v3.7Z"
      transform="translate(14760.002 -15423.998) rotate(-90)"
      fill="url(#linear-gradient)"
    />
  </svg>
);

export default WarningIcon;
