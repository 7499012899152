import React from "react";

const ManagementIcon = () => (
  <svg className="management-icon" x="0px" y="0px" viewBox="0 0 22 22">
    <g id="g3027" transform="translate(0 1408)">
      <path
        id="path3029"
        d="M14.667-1397a3.533,3.533,0,0,0-1.074-2.593A3.533,3.533,0,0,0,11-1400.667a3.533,3.533,0,0,0-2.592,1.074A3.533,3.533,0,0,0,7.333-1397a3.533,3.533,0,0,0,1.074,2.592A3.533,3.533,0,0,0,11-1393.333a3.533,3.533,0,0,0,2.592-1.074A3.533,3.533,0,0,0,14.667-1397ZM22-1398.561v3.18a.552.552,0,0,1-.115.329.436.436,0,0,1-.286.186l-2.65.4a9.171,9.171,0,0,1-.559,1.3q.5.716,1.533,1.977a.553.553,0,0,1,.143.358.478.478,0,0,1-.129.329,14.549,14.549,0,0,1-1.418,1.547q-1.031,1.017-1.346,1.017a.7.7,0,0,1-.372-.129l-1.977-1.547a8.192,8.192,0,0,1-1.3.544,23.436,23.436,0,0,1-.415,2.664.482.482,0,0,1-.516.4H9.41a.541.541,0,0,1-.351-.122.424.424,0,0,1-.165-.308l-.4-2.635a8.867,8.867,0,0,1-1.289-.53l-2.02,1.533a.515.515,0,0,1-.358.129.493.493,0,0,1-.358-.158,18.91,18.91,0,0,1-2.363-2.406.56.56,0,0,1-.1-.329.552.552,0,0,1,.115-.329q.215-.3.73-.952t.773-1.01a7.082,7.082,0,0,1-.587-1.418l-2.621-.387a.451.451,0,0,1-.3-.179.542.542,0,0,1-.115-.337v-3.18a.552.552,0,0,1,.115-.329.431.431,0,0,1,.272-.186l2.664-.4a7.071,7.071,0,0,1,.559-1.318q-.573-.816-1.533-1.977a.538.538,0,0,1-.143-.344.6.6,0,0,1,.129-.329,14.236,14.236,0,0,1,1.411-1.54q1.038-1.024,1.354-1.024a.61.61,0,0,1,.372.143l1.977,1.533a8.17,8.17,0,0,1,1.3-.544,23.427,23.427,0,0,1,.415-2.664.482.482,0,0,1,.516-.4h3.18a.54.54,0,0,1,.351.122.424.424,0,0,1,.165.308l.4,2.635a8.84,8.84,0,0,1,1.289.53l2.034-1.533a.464.464,0,0,1,.344-.129.554.554,0,0,1,.358.143,19.582,19.582,0,0,1,2.363,2.435.462.462,0,0,1,.1.315.552.552,0,0,1-.115.329q-.215.3-.73.953t-.773,1.01a8.623,8.623,0,0,1,.587,1.4l2.621.4a.451.451,0,0,1,.3.179A.542.542,0,0,1,22-1398.561Z"
        transform="translate(0)"
        stroke="#656588"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default ManagementIcon;
