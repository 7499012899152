import React from "react";

const LiveViewIcon = () => (
  <svg className="liveview-icon" x="0px" y="0px" viewBox="0 0 29.952 19.255">
    <g id="g4" transform="translate(23.804 1179.218)">
      <path
        id="path6"
        d="M4.009-1169.59a16.429,16.429,0,0,0-6.368-5.9,7.3,7.3,0,0,1,1.02,3.761,7.211,7.211,0,0,1-2.2,5.29,7.212,7.212,0,0,1-5.29,2.2,7.212,7.212,0,0,1-5.29-2.2,7.211,7.211,0,0,1-2.2-5.29,7.3,7.3,0,0,1,1.02-3.761,16.429,16.429,0,0,0-6.368,5.9,17.254,17.254,0,0,0,5.574,5.457,13.761,13.761,0,0,0,7.262,2.031,13.762,13.762,0,0,0,7.262-2.031A17.256,17.256,0,0,0,4.009-1169.59Zm-12.034-6.419a.773.773,0,0,0-.234-.568.774.774,0,0,0-.568-.234,4.891,4.891,0,0,0-3.585,1.5,4.891,4.891,0,0,0-1.5,3.585.774.774,0,0,0,.234.568.773.773,0,0,0,.568.234.774.774,0,0,0,.568-.234.774.774,0,0,0,.234-.568,3.349,3.349,0,0,1,1.02-2.457,3.349,3.349,0,0,1,2.457-1.019.774.774,0,0,0,.568-.234A.773.773,0,0,0-8.026-1176.009Zm14.174,6.419a2.314,2.314,0,0,1-.334,1.153,17.732,17.732,0,0,1-6.293,6.159,16.268,16.268,0,0,1-8.349,2.315,16.223,16.223,0,0,1-8.349-2.323,17.847,17.847,0,0,1-6.293-6.151,2.314,2.314,0,0,1-.334-1.153,2.314,2.314,0,0,1,.334-1.153,17.848,17.848,0,0,1,6.293-6.151,16.223,16.223,0,0,1,8.349-2.323,16.223,16.223,0,0,1,8.349,2.323,17.848,17.848,0,0,1,6.293,6.151A2.314,2.314,0,0,1,6.148-1169.59Z"
        transform="translate(0)"
        stroke="#656588"
        strokeWidth="0.1"
      />
    </g>
  </svg>
);

export default LiveViewIcon;
